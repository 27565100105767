import { configureStore } from "@reduxjs/toolkit";
import furniture from "./features/furniture.slice";
import location from "./features/location.slice";

const store = configureStore({
  reducer: {
    furniture,
    location,
  },
});

export default store;
