import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    location: null,
  },
  reducers: {
    newLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});
export const { newLocation } = locationSlice.actions;

export default locationSlice.reducer;
